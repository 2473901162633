import styled from "styled-components";

interface IEmojiProp {
  text: string;
}

const emojiRegex = /<(a?):[a-zA-Z0-9]+:(\d+)>/;

const defaultEmojiMap: { [name: string]: React.SVGProps<SVGSVGElement> } = {
  busts_in_silhouette: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
      <path
        fill="#55ACEE"
        d="M29 20.729v-1.963c1-1.03 2.914-2.89 3.391-5.273.142.079.055.13.213.13.758 0 1.256-.983 1.256-2.197 0-1.194-.656-2.161-1.399-2.191.143-.516.212-1.206.212-2.092 0-2.956-2.549-6.505-8.253-6.505-5.068 0-8.244 3.549-8.244 6.505 0 .858.051 1.562.142 2.107-.697.105-1.247 1.033-1.247 2.175 0 1.214.614 2.197 1.373 2.197.157 0-.069-.051.072-.13.477 2.384 2.484 4.243 3.484 5.274v1.847c-4 .492-7 2.628-7 4.765v.81c0 .812.823.812 1.634.812h18.73c.813 0 1.636 0 1.636-.812v-.81c0-2.001-3-3.997-6-4.649z"
      />
      <path
        fill="#269"
        d="M17 28.729v-1.963c1-1.03 2.914-2.89 3.391-5.273.142.079.055.13.213.13.758 0 1.256-.983 1.256-2.197 0-1.194-.656-2.161-1.399-2.191.143-.516.212-1.206.212-2.092 0-2.956-2.549-6.505-8.253-6.505-5.069 0-8.244 3.549-8.244 6.505 0 .858.051 1.562.142 2.107-.697.105-1.247 1.033-1.247 2.175 0 1.214.614 2.197 1.373 2.197.157 0-.069-.051.072-.13C4.993 23.876 7 25.735 8 26.766v1.847c-4 .492-7 2.628-7 4.765v.811C1 35 1.823 35 2.634 35h18.73c.813 0 1.636 0 1.636-.812v-.811c0-2-3-3.996-6-4.648z"
      />
    </svg>
  ),
  trophy: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
      <path
        fill="#FFAC33"
        d="M5.123 5h6C12.227 5 13 4.896 13 6V4c0-1.104-.773-2-1.877-2h-8c-2 0-3.583 2.125-3 5 0 0 1.791 9.375 1.917 9.958C2.373 18.5 4.164 20 6.081 20h6.958c1.105 0-.039-1.896-.039-3v-2c0 1.104-.773 2-1.877 2h-4c-1.104 0-1.833-1.042-2-2S3.539 7.667 3.539 7.667C3.206 5.75 4.018 5 5.123 5zm25.812 0h-6C23.831 5 22 4.896 22 6V4c0-1.104 1.831-2 2.935-2h8c2 0 3.584 2.125 3 5 0 0-1.633 9.419-1.771 10-.354 1.5-2.042 3-4 3h-7.146C21.914 20 22 18.104 22 17v-2c0 1.104 1.831 2 2.935 2h4c1.104 0 1.834-1.042 2-2s1.584-7.333 1.584-7.333C32.851 5.75 32.04 5 30.935 5zM20.832 22c0-6.958-2.709 0-2.709 0s-3-6.958-3 0-3.291 10-3.291 10h12.292c-.001 0-3.292-3.042-3.292-10z"
      />
      <path
        fill="#FFCC4D"
        d="M29.123 6.577c0 6.775-6.77 18.192-11 18.192-4.231 0-11-11.417-11-18.192 0-5.195 1-6.319 3-6.319 1.374 0 6.025-.027 8-.027l7-.001c2.917-.001 4 .684 4 6.347z"
      />
      <path
        fill="#C1694F"
        d="M27 33c0 1.104.227 2-.877 2h-16C9.018 35 9 34.104 9 33v-1c0-1.104 1.164-2 2.206-2h13.917c1.042 0 1.877.896 1.877 2v1z"
      />
      <path
        fill="#C1694F"
        d="M29 34.625c0 .76.165 1.375-1.252 1.375H8.498C7.206 36 7 35.385 7 34.625v-.25C7 33.615 7.738 33 8.498 33h19.25c.759 0 1.252.615 1.252 1.375v.25z"
      />
    </svg>
  ),
};

export default function Emoji({ text }: IEmojiProp) {
  if (text in defaultEmojiMap) {
    return (
      <EmojiImage>
        <>{defaultEmojiMap[text]}</>
      </EmojiImage>
    );
  }

  const exec = emojiRegex.exec(text);

  if (!exec) return null;

  const ext = exec[1] === "a" ? "gif" : "png";
  const id = exec[2];

  return (
    <EmojiImage>
      <img src={`https://cdn.discordapp.com/emojis/${id}.${ext}`} alt="emoji" />
    </EmojiImage>
  );
}

export const EmojiImage = styled.div`
  width: 48px;
  height: 48px;

  display: inline-block;
  vertical-align: middle;

  user-select: none;
  pointer-events: none;
`;
